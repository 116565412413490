// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-background-color-js": () => import("./../../../src/pages/background-color.js" /* webpackChunkName: "component---src-pages-background-color-js" */),
  "component---src-pages-background-gradient-js": () => import("./../../../src/pages/background-gradient.js" /* webpackChunkName: "component---src-pages-background-gradient-js" */),
  "component---src-pages-background-image-js": () => import("./../../../src/pages/background-image.js" /* webpackChunkName: "component---src-pages-background-image-js" */),
  "component---src-pages-border-js": () => import("./../../../src/pages/border.js" /* webpackChunkName: "component---src-pages-border-js" */),
  "component---src-pages-border-radius-js": () => import("./../../../src/pages/border-radius.js" /* webpackChunkName: "component---src-pages-border-radius-js" */),
  "component---src-pages-box-shadow-js": () => import("./../../../src/pages/box-shadow.js" /* webpackChunkName: "component---src-pages-box-shadow-js" */),
  "component---src-pages-filter-blur-js": () => import("./../../../src/pages/filter-blur.js" /* webpackChunkName: "component---src-pages-filter-blur-js" */),
  "component---src-pages-filter-brightness-js": () => import("./../../../src/pages/filter-brightness.js" /* webpackChunkName: "component---src-pages-filter-brightness-js" */),
  "component---src-pages-filter-contrast-js": () => import("./../../../src/pages/filter-contrast.js" /* webpackChunkName: "component---src-pages-filter-contrast-js" */),
  "component---src-pages-filter-grayscale-js": () => import("./../../../src/pages/filter-grayscale.js" /* webpackChunkName: "component---src-pages-filter-grayscale-js" */),
  "component---src-pages-filter-hue-rotate-js": () => import("./../../../src/pages/filter-hue-rotate.js" /* webpackChunkName: "component---src-pages-filter-hue-rotate-js" */),
  "component---src-pages-filter-invert-js": () => import("./../../../src/pages/filter-invert.js" /* webpackChunkName: "component---src-pages-filter-invert-js" */),
  "component---src-pages-filter-saturate-js": () => import("./../../../src/pages/filter-saturate.js" /* webpackChunkName: "component---src-pages-filter-saturate-js" */),
  "component---src-pages-filter-sepia-js": () => import("./../../../src/pages/filter-sepia.js" /* webpackChunkName: "component---src-pages-filter-sepia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-flexbox-gallery-js": () => import("./../../../src/pages/layout-flexbox-gallery.js" /* webpackChunkName: "component---src-pages-layout-flexbox-gallery-js" */),
  "component---src-pages-layout-flexbox-items-js": () => import("./../../../src/pages/layout-flexbox-items.js" /* webpackChunkName: "component---src-pages-layout-flexbox-items-js" */),
  "component---src-pages-layout-flexbox-menu-bar-js": () => import("./../../../src/pages/layout-flexbox-menu-bar.js" /* webpackChunkName: "component---src-pages-layout-flexbox-menu-bar-js" */),
  "component---src-pages-layout-float-js": () => import("./../../../src/pages/layout-float.js" /* webpackChunkName: "component---src-pages-layout-float-js" */),
  "component---src-pages-layout-grid-items-js": () => import("./../../../src/pages/layout-grid-items.js" /* webpackChunkName: "component---src-pages-layout-grid-items-js" */),
  "component---src-pages-layout-grid-page-js": () => import("./../../../src/pages/layout-grid-page.js" /* webpackChunkName: "component---src-pages-layout-grid-page-js" */),
  "component---src-pages-text-js": () => import("./../../../src/pages/text.js" /* webpackChunkName: "component---src-pages-text-js" */),
  "component---src-pages-text-shadow-js": () => import("./../../../src/pages/text-shadow.js" /* webpackChunkName: "component---src-pages-text-shadow-js" */),
  "component---src-pages-transform-rotate-js": () => import("./../../../src/pages/transform-rotate.js" /* webpackChunkName: "component---src-pages-transform-rotate-js" */),
  "component---src-pages-transform-scale-js": () => import("./../../../src/pages/transform-scale.js" /* webpackChunkName: "component---src-pages-transform-scale-js" */),
  "component---src-pages-transform-skew-js": () => import("./../../../src/pages/transform-skew.js" /* webpackChunkName: "component---src-pages-transform-skew-js" */),
  "component---src-pages-transform-translate-js": () => import("./../../../src/pages/transform-translate.js" /* webpackChunkName: "component---src-pages-transform-translate-js" */)
}

